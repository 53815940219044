<template>
	<a-space direction="vertical">
		<a-card title="查询条件">
			<a-form-model @submit.prevent="onSearch" layout="horizontal" v-bind="{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }">
				<a-row>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="订单编号"><a-input v-model="query.orderId" placeholder="请输入订单编号"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="用户ID"><a-input v-model="query.userId" placeholder="请输入用户ID"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="用户手机号"><a-input v-model="query.mobile" placeholder="请输入用户手机号"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="类型">
							<a-select style="width: 100%" v-model="query.type" placeholder="请选择类型" allow-clear>
								<a-select-option value>全部</a-select-option>
								<a-select-option :value="1">消费</a-select-option>
								<a-select-option :value="2">代付</a-select-option>
								<a-select-option :value="3">收款</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="状态">
							<a-select style="width: 100%" v-model="query.status" placeholder="请选择状态" allow-clear>
								<a-select-option value>全部</a-select-option>
								<a-select-option :value="2">已完成</a-select-option>
								<a-select-option :value="1">待结算</a-select-option>
								<a-select-option :value="0">未分佣</a-select-option>
								<a-select-option :value="-1">取消结算</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="时间"><a-range-picker :value="selectedDateRange" @change="onChange" /></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="上级手机号"><a-input v-model="query.superior" placeholder="请输入上级手机号"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="用户等级">
							<a-select style="width: 100%" v-model="query.roleId" placeholder="请选择用户等级" allow-clear>
								<a-select-option value>全部</a-select-option>
								<a-select-option v-for="(text, key) in ROLES" :key="key" :value="key">{{ text }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>

					<a-col :md="8" :sm="24">
						<a-form-model-item style="text-align: right;">
							<a-space>
								<a-button type="primary" html-type="submit">查询</a-button>
								<a-button @click="onReset">重置</a-button>
							</a-space>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-card>

		<a-card title="订单管理">
			<a-space slot="extra">
				<a-button type="primary" @click="onRealname">实名列表</a-button>
				<a-button @click="onExport">导出</a-button>
			</a-space>

			<div class="statistics">
				<span>订单总数：{{ lists.total }}</span>
				<span>订单用户：{{ lists.totalUser }}</span>
				<span>交易总额：{{ lists.totalAmount }}</span>
				<span>佣金总额：{{ lists.totalCommission }}</span>
				<span>分佣总额：{{ lists.totalSettleCommission }}</span>
			</div>

			<a-table
				:data-source="lists.data"
				:pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }"
				@change="onPageChange"
				:scroll="{ x: 'max-content' }"
			>
				<a-table-column key="id" title="订单编号" data-index="id">
					<template slot-scope="id, record">
						<div>
							{{ record.orderId }}
							<a href="javascript:;" v-clipboard:copy="record.orderId" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
						</div>
					</template>
				</a-table-column>
				<a-table-column key="userId" title="用户信息" data-index="userId">
					<template slot-scope="text, record">
						<div v-if="!!text">
							{{ text }}
							<br />
							{{ record.nickname }} （{{ record.mobile }}
							<a href="javascript:;" v-clipboard:copy="record.mobile" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
							）
						</div>
					</template>
				</a-table-column>
				<a-table-column key="roleIdStr" title="用户等级" data-index="roleIdStr" />
				<a-table-column key="type" title="交易类型" data-index="type">
					<template slot-scope="type, record">
						<div>
							<a-tag :color="['', 'orange', 'green', 'blue'][record.type]">{{ record.typeStr }}</a-tag>
						</div>
					</template>
				</a-table-column>
				<a-table-column key="amount" title="交易金额" data-index="amount"></a-table-column>
				<a-table-column key="userFeeRate" title="用户费率/代付费" data-index="userFeeRate"></a-table-column>
				<a-table-column key="agentFeeRate" title="代理费率/代理价" data-index="agentFeeRate" />
				<a-table-column key="commission" title="佣金金额" data-index="commission" />
				<a-table-column key="settleCommission" title="分佣金额" data-index="settleCommission" />
				<a-table-column key="ctime" title="交易完成时间" data-index="ctime" />
				<a-table-column key="status" title="交易类型" data-index="status">
					<template slot-scope="status, record">
						<div>
							<a-tag :color="['orange', 'blue', 'green'][record.status]">{{ record.statusStr }}</a-tag>
						</div>
					</template>
				</a-table-column>
				<a-table-column key="action" title="操作" fixed="right">
					<template slot-scope="text, record">
						<a-space v-if="record.show == 1"><a-button size="small" @click="onCommission(record)">分佣明细</a-button></a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>

		<a-modal title="实名列表" v-model="modal.visible" :width="1024" :footer="null" centered>
			<div class="binding-lists">
				<a-form-model layout="inline">
					<a-form-model-item label="订单时间"><a-range-picker :value="selectedDailyDateRange" style="width:230px" @change="onDailyChange" /></a-form-model-item>
					<a-form-model-item label="手机号/昵称"><a-input v-model="modal.query.mobile" placeholder="请输入用户手机号/昵称" /></a-form-model-item>
					<a-form-model-item>
						<a-space>
							<a-button type="primary" @click="onModalSearch">查询</a-button>
							<a-button @click="onModalReset">重置</a-button>
						</a-space>
					</a-form-model-item>
				</a-form-model>
				<div class="statistics" style="margin-bottom: 20px;">
					<a-button style="float: right;" @click="onExportRealname">导出</a-button>
					<span>实名总数：{{ modal.lists.total }}</span>
					<span>绑卡总数：{{ modal.lists.bindCard }}</span>
				</div>
				<a-table
					rowKey="userId"
					size="small"
					style="margin-top: 10px;"
					:data-source="modal.lists.data"
					:pagination="{ current: modal.lists.current_page, pageSize: modal.lists.per_page, total: modal.lists.total }"
					@change="onDailyPageChange"
					:scroll="{ x: 'max-content' }"
					bordered
				>
					<a-table-column key="mobile" title="用户" data-index="mobile">
						<template slot-scope="text, record" fixed="left">
							{{ record.realName }}/{{ record.mobile }}
						</template>
					</a-table-column>
					<a-table-column key="ctime" title="创建时间" data-index="ctime" />
					<a-table-column key="roleIdStr" title="用户级别" data-index="roleIdStr" align="center">
						<template slot-scope="text, record" fixed="left">
							{{ record.roleIdStr }}
							<a href="javascript:;" v-if="modal.roles.length > 0" @click="onUpdateRole(record)"><a-icon type="edit" /></a>
						</template>
					</a-table-column>
					<a-table-column key="repaymentRate" title="智还费率/笔数费" data-index="repaymentRate" align="center">
						<template slot-scope="text, record">
							{{ record.repaymentRate }} / {{ record.repaymentCharge }}
						</template>
					</a-table-column>
					<a-table-column key="collectionRate" title="收款费率/笔数费" data-index="collectionRate" align="center">
						<template slot-scope="text, record">
							{{ record.collectionRate }} / {{ record.collectionCharge }}
						</template>
					</a-table-column>
					<a-table-column key="lastLoginTime" title="登录时间" data-index="lastLoginTime" align="center" />
					<a-table-column key="repaymentDay" title="操作" data-index="repaymentDay" fixed="right" align="center">
						<template slot-scope="text, record">
							<a-button type="primary" size="small" v-if="record.show == 1" @click="onBinding(record)">绑卡列表</a-button>
						</template>
					</a-table-column>
				</a-table>
			</div>
		</a-modal>

		<a-modal v-model="modalCommission.visible" title="分佣详情" :width="800" :footer="null">
			<a-table :data-source="modalCommission.data" :pagination="false" :scroll="{ x: 'max-content' }">
				<a-table-column key="mobile" title="用户" data-index="mobile">
					<template slot-scope="text, record">
						{{ record.nickname }}
						<br />
						({{ record.mobile }})
					</template>
				</a-table-column>
				<a-table-column key="roleIdStr" title="用户等级" data-index="roleIdStr" />
				<a-table-column key="type" title="交易类型" data-index="type">
					<template slot-scope="type, record">
						<div>
							<a-tag :color="['', 'orange', 'green', 'blue'][record.type]">{{ record.typeStr }}</a-tag>
						</div>
					</template>
				</a-table-column>
				<a-table-column key="standard" title="分佣比例" data-index="standard" />
				<a-table-column key="money" data-index="money" align="center">
					<div slot="title">
						金额
						<br />
						<span style="font-size: 12px;font-weight: normal;color:#1890ff">({{ modalCommission.txAmount }}/{{ modalCommission.totalCommission }})</span>
					</div>
				</a-table-column>
				<a-table-column key="statusStr" title="状态" data-index="statusStr" />
				<a-table-column key="settleTime" title="结算时间" data-index="settleTime" />
			</a-table>
		</a-modal>

		<a-modal v-model="modalBinding.visible" title="绑卡列表" :width="800" :footer="null" centered>
			<a-table :data-source="modalBinding.lists.data" size="small" style="margin-top: 10px;" :pagination="false" :scroll="{ x: 'max-content' }" bordered>
				<a-table-column key="index" title="序号" data-index="index" align="center">
					<template slot-scope="text, record, index">
						{{ index + 1 }}
					</template>
				</a-table-column>

				<a-table-column key="ctime" title="创建时间" data-index="ctime" align="center" />
				<a-table-column key="cardNumber" title="卡号（后四位）" data-index="cardNumber" align="center" />
				<a-table-column key="type" title="卡类型" data-index="type" align="center" />
				<a-table-column key="billingDay" title="账单日" data-index="billingDay" align="center" />
				<a-table-column key="repaymentDay" title="还款日" data-index="repaymentDay" align="center" />
			</a-table>
		</a-modal>

		<a-modal v-model="modalRole.visible" title="调整等级">
			<a-space slot="footer">
				<a-button @click="modalRole.visible = false">取消</a-button>
				<a-button type="primary" @click="onSaveRole">确定</a-button>
			</a-space>

			<a-form-model ref="form" :model="modalRole.formdata" :rules="modalRole.rules" v-bind="layout">
				<a-form-model-item label="等级">
					<a-radio-group v-model="modalRole.formdata.roleId">
						<a-radio style="display:block;" :key="option.roleId" :value="option.roleId" v-for="option in modal.roles">{{ option.roleStr }}</a-radio>
					</a-radio-group>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</a-space>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { parseQuery } from '@/utils';
import { ROLES } from '@/utils/constants';

const formatter = 'YYYY-MM-DD';
const DEFAULT_QUERY = {
	page: 1,
	size: 10,
	mobile: undefined,
	beginTime: moment()
		.startOf('month')
		.format(formatter),
	endTime: moment().format(formatter),
	userId: undefined,
	type: undefined,
	status: undefined,
	orderId: undefined,
	superior: undefined,
	roleId: undefined
};
export default {
	data() {
		return {
			layout: {
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 18
				}
			},
			ROLES,
			query: Object.assign({}, DEFAULT_QUERY),
			lists: {
				data: [],
				current_page: 1,
				per_page: 10,
				total: 0,
				totalUser: 0,
				totalCommission: 0,
				totalSettleCommission: 0
			},
			modal: {
				visible: false,
				query: {
					mobile: undefined,
					beginTime: undefined,
					endTime: undefined,
					page: 1
				},
				roles: [],
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0,
					bindCard: 0
				}
			},
			modalCommission: {
				visible: false,
				data: [],
				totalCommission: 0,
				txAmount: 0
			},
			modalRealname: {
				visible: false,
				data: []
			},
			modalBinding: {
				visible: false,
				query: {
					page: 1
				},
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0
				}
			},
			modalRole: {
				visible: false,
				formdata: { roleId: undefined, userId: undefined },
				rules: { roleId: [{ required: true, message: '请选择用户等级', trigger: 'change' }] }
			}
		};
	},
	computed: {
		selectedDateRange() {
			const { beginTime, endTime } = this.query;
			if (beginTime && endTime) {
				return [moment(beginTime, formatter), moment(endTime, formatter)];
			} else {
				return null;
			}
		},
		selectedDailyDateRange() {
			const { beginTime, endTime } = this.modal.query;
			if (beginTime && endTime) {
				return [moment(beginTime, formatter), moment(endTime, formatter)];
			} else {
				return null;
			}
		}
	},

	async mounted() {
		this.getOrders();
	},
	methods: {
		async getOrders() {
			const response = await this.$api.get('/credit_card_list', {
				params: this.query
			});
			if (response && response.code == 200) {
				this.lists = Object.assign(this.lists, response.data);
			}
		},
		async onPageChange(pagination, filters, sorter) {
			this.query.page = pagination.current;
			this.query.size = pagination.pageSize;
			this.getOrders();
		},
		async onSearch() {
			this.query.page = 1;
			this.getOrders();
		},
		async onReset() {
			this.query = Object.assign({}, DEFAULT_QUERY);
			this.getOrders();
		},
		async onExport() {
			this.$store.dispatch('setLoading', true);
			const response = await this.$api.get('/credit_card_export', {
				params: this.query,
				responseType: 'arraybuffer'
			});
		},
		async onChange(o, dateStr) {
			if (dateStr.length === 2) {
				this.query.beginTime = dateStr[0];
				this.query.endTime = dateStr[1];
			} else {
				this.query.beginTime = undefined;
				this.query.endTime = undefined;
			}
		},
		async onCommission(record) {
			const response = await this.$api.get(`/credit_card_commission/${record.id}`);
			console.info('response', response);
			if (response && response.code == 200) {
				Object.assign(this.modalCommission, response.data);
				this.modalCommission.visible = true;
			} else {
				this.$message.error(response.msg);
			}
		},
		async onRealname() {
			if (this.modal.roles.length === 0) {
				const res = await this.$api.get(`/credit_role_info`);
				if (res.code == 200) {
					this.modal.roles = res.data || [];
				}
			}
			const response = await this.$api.get(`/credit_real_name`, { params: this.modal.query });
			console.info('response', response);
			if (response && response.code == 200) {
				this.modal.lists = Object.assign(this.modal.lists, response.data);
				this.modal.visible = true;
			} else {
				this.$message.error(response.msg);
			}
		},
		async onDailyChange(o, dateStr) {
			if (dateStr.length === 2) {
				this.modal.query.beginTime = dateStr[0];
				this.modal.query.endTime = dateStr[1];
			} else {
				this.modal.query.beginTime = undefined;
				this.modal.query.endTime = undefined;
			}
		},
		async onDailyPageChange(pagination) {
			this.modal.query.page = pagination.current;
			this.modal.query.size = pagination.pageSize;
			this.onRealname();
		},
		async onModalSearch() {
			this.modal.query.page = 1;
			this.onRealname();
		},
		async onModalReset() {
			this.modal.query = {
				mobile: undefined,
				beginTime: undefined,
				endTime: undefined,
				page: 1
			};
			this.onRealname();
		},
		async onExportRealname() {
			this.$store.dispatch('setLoading', true);
			const response = await this.$api.get('/credit_real_name_export', {
				params: this.modal.query,
				responseType: 'arraybuffer'
			});
		},
		async onBinding(record) {
			const response = await this.$api.get(`/credit_card_bind/${record.userId}`);
			console.info('response', response);
			if (response && response.code == 200) {
				// Object.assign(this.modalBinding.lists, response.data);
				this.modalBinding.lists.data = response.data;
				this.modalBinding.visible = true;
			} else {
				this.$message.error(response.msg);
			}
		},

		async onBindingPageChange(pagination) {
			this.modalBinding.query.page = pagination.current;
			this.modalBinding.query.size = pagination.pageSize;
			this.onRealname();
		},
		onUpdateRole(record) {
			this.modalRole.formdata = {
				roleId: record.creditRoleId,
				userId: record.userId
			};
			this.modalRole.visible = true;
		},
		onSaveRole() {
			const { roleId, userId } = this.modalRole.formdata;
			this.$refs.form.validate(async valid => {
				if (valid) {
					const response = await this.$api.post('/credit_change_role', { roleId, userId });
					if (response && response.code == 200) {
						this.$message.success('操作成功');
						this.onRealname();
						this.modalRole.visible = false;
					} else {
						this.$message.error(response.msg);
					}
				}
			});
		}
	}
};
</script>

<style lang="less">
.binding-lists {
	.ant-form-item {
		margin-bottom: 10px;
	}
}
</style>
